import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { SubGroup } from '../interfaces/subgroup';
import { TertiaryGroup } from '../interfaces/tertiarygroup';

@Injectable({
    providedIn: 'root',
})
export class TertiarygroupsService {
    public subgroupLogoSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public subgroupLogo$: Observable<string> = this.subgroupLogoSubject.asObservable();

    constructor(private httpService: HttpService) {}

    public getTertiaryGroups(appId: string): Observable<TertiaryGroup[]> {
        const endpoint = `api/v1/tertiary_group/?limit=0&subgroup__app_id=${appId}`;
        return this.httpService.get(endpoint).pipe(
            map(resp => resp.objects),
            tap((tertiaryGroups: TertiaryGroup[]) =>
                localStorage.setItem('tertiaryGroups', JSON.stringify(tertiaryGroups)),
            ),
        );
    }

    public getTertiaryGroupsFromStorage(): Observable<TertiaryGroup[]> {
        const tertiaryGroupsFromStorage = localStorage.getItem('tertiaryGroups') || '';
        return of(tertiaryGroupsFromStorage).pipe(map(tertiaryGroupsStoraged => JSON.parse(tertiaryGroupsStoraged)));
    }

    public getTertiaryGroup(tertiaryGroupId: number): Observable<TertiaryGroup> {
        const endpoint = `api/v1/tertiary_group/${tertiaryGroupId}/`;
        return this.httpService.get(endpoint);
    }

    public getSubGroup(appId: string): Observable<SubGroup> {
        const endpoint = `api/v1/subgroup/?app_id=${appId}`;
        return this.httpService.get(endpoint).pipe(map(resp => resp.objects[0]));
    }
}
