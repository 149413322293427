import { Component, Input } from '@angular/core';

import { Router } from '@angular/router';
import { TertiarygroupsService } from '../../services/tertiarygroups.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input('drawer') drawer: any;

    routeTitles: { [key: string]: string } = {
        '/login': 'Login',
        '/buscador': 'BUSCADOR DE CÉLULAS',
        '/buscador/enviar-indicacao': 'BUSCADOR DE CÉLULAS',
        '/eventos/meus-tickets': 'Meus Tickets',
        '/doacoes': 'Doações',
        '/membresia': 'Membresia',
        '/membresia/visitantes': 'Visitantes',
        '/membresia/membros': 'Membros',
    };

    switchTheme: boolean;
    defaultLogo: string = '../../../../assets/icons/inchurch.svg';
    logo: string;

    constructor(
        private router: Router,
        private tertiarygroupService: TertiarygroupsService,
    ) {}

    ngOnInit() {
        this.tertiarygroupService.subgroupLogo$.subscribe(logo => {
            this.logo = logo || this.defaultLogo;
        });

        let themeString = localStorage.getItem('panel-theme');

        if (themeString) {
            let themeObj = JSON.parse(themeString);
            themeObj.value
                ? (document.body.className = document.body.className.replace(
                      'theme-default-light',
                      'theme-default-dark',
                  ))
                : (document.body.className = document.body.className.replace(
                      'theme-default-dark',
                      'theme-default-light',
                  ));
            this.switchTheme = themeObj.value;
        }
    }

    isPaginaBuscador(): boolean {
        return this.router.url === '/buscador' || this.router.url === '/buscador/enviar-indicacao';
    }

    public getTitle(): string {
        const currentUrl = this.router.url;
        const defaultTitle = '';
        return this.routeTitles[currentUrl] || defaultTitle;
    }

    toggleSwitch() {
        this.switchTheme = !this.switchTheme;
        this.switchTheme === true
            ? (document.body.className = document.body.className.replace('theme-default-light', 'theme-default-dark'))
            : (document.body.className = document.body.className.replace('theme-default-dark', 'theme-default-light'));
        let theme = this.switchTheme
            ? { name: 'theme-default-dark', value: true }
            : { name: 'theme-default-light', value: false };
        localStorage.setItem('panel-theme', JSON.stringify(theme));
    }
}
