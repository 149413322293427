export const locale = {
    lang: 'es',
    data: {
        PT_BR_TITLE: 'Portugués-BR',
        PT_PT_TITLE: 'Portugués-PT',
        EN_TITLE: 'Inglés',
        ES_TITLE: 'Español',
        COUNTRY: "País",
        PAGINATOR: {
            ITEMS_PER_PAGE: 'Artículos por página:',
            NEXT_PAGE: 'Siguiente página',
            PREVIOUS_PAGE: 'Pagina anterior',
            OF_LABEL: 'de',
        },
        ERRORS: {
            DENOMINATION_NOT_PROVIDED: '¡Denominación no fue informada en la URL!',
        },
        BILLET_MODAL: {
            TITLE: 'Boleto',
            DESCRIPTION: 'Su boleto fue generado con éxito. Utilice el código de barras a continuación para realizar el pago en cualquier banco, casa de loterías o aplicación de pago.',
            COPY_CODE_BUTTON: 'Copiar código',
            CLOSE_BUTTON: 'Cerrar',
        },
        PAGES_TITLES: {
            LOGIN_TITLE: 'Iniciar sesión',
            CELL_FINDER_TITLE: 'Buscador de Células',
            MY_TICKETS_TITLE: 'Mis Tickets',
            DONATIONS_TITLE: 'Donaciones',
            MEMBERSHIP_TITLE: 'Membresía',
            VISITORS_TITLE: 'Visitantes',
            MEMBERS_TITLE: 'Miembros',
        },
        MENU: {
            USER: 'Usuario',
            LOGIN: 'Iniciar sesión',
            MY_TICKETS: 'Mis Tickets',
            LOGOUT: 'Cerrar sesión',
        },
        GENDER: {
            FEMALE: 'Femenino',
            MALE: 'Masculino',
        },
        CIVIL_STATES: {
            SINGLE: 'Soltero(a)',
            ENGAGED: 'Comprometido(a)',
            MARRIED: 'Casado(a)',
            DIVORCED: 'Divorciado(a)',
            WIDOWER: 'Viudo(a)',
            STABLE_UNION: 'Unión estable',
        },
        JOINING_TYPE: {
            BAPTISM: 'Bautismo: fui bautizado(a) en la iglesia.',
            TRANSFER: 'Transferencia: vine de otra iglesia y pasé por el proceso de transferencia.',
            RECONCILIATION: 'Reconciliación: estuve alejado(a) y regresé a la iglesia.',
            JURISDICTION: 'Jurisdicción: vine de otra iglesia de la misma denominación.',
            ACCLAIM: 'Aclamación: vine de otra iglesia y pasé por el proceso de aclamación.',
        },
        EDUCATIONAL_LEVEL: {
            UNSCHOOLED: 'No escolarizado',
            INCOMPLETED_PRIMARY: 'Educación Primaria Incompleta',
            COMPLETED_PRIMARY: 'Educación Primaria Completa',
            INCOMPLETED_HIGH_SCHOOL: 'Educación Secundaria Incompleta',
            COMPLETED_HIGH_SCHOOL: 'Educación Secundaria Completa',
            INCOMPLETED_HIGHER_EDUCATION: 'Educación Superior Incompleta',
            COMPLETED_HIGHER_EDUCATION: 'Educación Superior Completa',
            INCOMPLETED_GRADUATE: 'Posgrado Incompleto',
            COMPLETED_GRADUATE: 'Posgrado Completo',
            INCOMPLETED_MASTERS: 'Maestría Incompleta',
            COMPLETED_MASTERS: 'Maestría Completa',
            INCOMPLETED_DOC: 'Doctorado Incompleto',
            COMPLETED_DOC: 'Doctorado Completo',
            INCOMPLETED_POSTDOC: 'Postdoctorado Incompleto',
            COMPLETED_POSTDOC: 'Postdoctorado Completo',
        },
        SESSION: {
            ALL: 'Todos',
            MORNING: 'Mañana',
            AFTERNOON: 'Tarde',
            NIGHT: 'Noche',
        },
        WEEKDAYS: {
            SUNDAY: 'Domingo',
            MONDAY: 'Lunes',
            TUESDAY: 'Martes',
            WEDNESDAY: 'Miércoles',
            THURSDAY: 'Jueves',
            FRIDAY: 'Viernes',
            SATURDAY: 'Sábado',
        },
        PARENTAGE: {
            FATHER: 'Padre',
            MOTHER: 'Madre',
            BROTHER: 'Hermano',
            SISTER: 'Hermana',
            GRANDFATHER: 'Abuelo',
            GRANDMOTHER: 'Abuela',
            UNCLE: 'Tío',
            AUNT: 'Tía',
            STEPFATHER: 'Padrastro',
            STEPMOTHER: 'Madrastra',
            MALE_COUSIN: 'Primo',
            FEMALE_COUSIN: 'Prima',
            GREAT_GRANDFATHER: 'Bisabuelo',
            GREAT_GRANDMOTHER: 'Bisabuela',
        },
        SNACKBAR: {
            LOGIN_FAILED: 'Error al iniciar sesión.',
            INVALID_DATA: 'Hay un error en los datos proporcionados.',
        },
    },
};
