<header class="flex-column flex-center h-104 theme-default-dark">
    <div class="flex-column flex-sm-row flex-space-between">
        <div class="flex-start">
            <button matcha-button size="md" pill="true" basic="true" color="primary" icon="true" (click)="drawer.toggle()">
                <span class="i-matcha-menu"></span>
            </button>
        </div>
        <div class="d-flex flex-center-center text-center">
            <div class="d-flex flex-center-center gap-24" [ngClass]="{ 'content-center': !getTitle() }">
                <img [src]="logo" alt="Logo" class="logo" />
                <h4 *ngIf="getTitle()" class="title-header m-0">{{ getTitle() | translate }}</h4>
            </div>
        </div>

        <!-- está assim apenas inicialmente, o design vai mudar  -->
        <div class="flex-sm-end flex-center-center d-flex gap-12 flex-align-center mt-8">
            <mat-form-field appearance="outline" class="select-input">
                <mat-label>{{ 'COUNTRY' | translate }}</mat-label>
                <mat-select [(value)]="selectedLanguage" (selectionChange)="changeLang($event.value)">
                    <mat-select-trigger class="flex-align-center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/' + getSelectedLanguage()?.flag + '.png'" alt="" height="16" />
                        {{ getSelectedLanguage()?.nameKey || '' | translate }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let lang of languages" [value]="lang.code">
                        {{ lang.nameKey | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button matcha-button size="md" pill="true" basic="true" icon="true" (click)="toggleSwitch()">
                <span *ngIf="!switchTheme" class="i-matcha-dark-mode-2"></span>
                <span *ngIf="switchTheme" class="i-matcha-light-mode"></span>
            </button>
        </div>
    </div>
</header>
<!-- TOP BACKGROUND -->
<div class="theme-default-dark position-absolute place-top place-left place-right z-index--1 h-128"></div>
<!-- / TOP BACKGROUND -->
