import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

export class LocalStorageEvent {
    key: string;
    newValue?: string;
    constructor(key: string, newValue?: string) {
        this.key = key;
        this.newValue = newValue;
    }
}

@Injectable({
    providedIn: 'root',
})
export class LocalstorageService {
    private storageSubject = new Subject<LocalStorageEvent>();

    watchStorage(): Observable<LocalStorageEvent> {
        return this.storageSubject.asObservable();
    }

    setItem(key: string, value: string): void {
        localStorage.setItem(key, value);
        this.storageSubject.next(new LocalStorageEvent(key, value));
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
        this.storageSubject.next(new LocalStorageEvent(key));
    }

}
