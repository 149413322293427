import * as Sentry from '@sentry/angular';

import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from './auth/services/auth.service';
import { Component } from '@angular/core';
import { LocalstorageService } from './common/services/localstorage.service';
import { TertiarygroupsService } from './common/services/tertiarygroups.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    private appId: string;

    defaultLogo: string = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private tertiarygroupService: TertiarygroupsService,
        private localStorageService: LocalstorageService,
    ) {}

    ngOnInit(): void {
        Sentry.setContext('browser', {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
        });

        //this.listeningLocalstorage();        

        this.activatedRoute.queryParams.subscribe(params => {
            this.appId = params['app_id'];
            if (this.appId) {
                const decodedAppId = atob(this.appId);
                const storedAppId = localStorage.getItem('appId');

                if (storedAppId && storedAppId !== decodedAppId) {
                    this.authService.clearLoginData();
                }

                this.localStorageService.setItem('appId', decodedAppId);
            }
        });

        this.localStorageService.watchStorage().subscribe(event => {
            if (event.key === 'appId') {
                if (event.newValue) {
                    this.handleAppIdChange(event.newValue);
                }
            }
        });

        this.reditectOldRoutes();
    }

    private handleAppIdChange(appId: string): void {
        this.tertiarygroupService.getSubGroup(appId).subscribe({
            next: subGroup => {
                const logo = subGroup.logo || this.defaultLogo;
                this.tertiarygroupService.subgroupLogoSubject.next(logo);
            },
            error: err => {
                console.error('Erro ao buscar subgrupo:', err);
            },
        });
    }

    private reditectOldRoutes(): void {
        const hash = window.location.hash;

        if (hash.startsWith('#/membership')) {
            const params = hash.replace('#/membership/', '');
            const paramsData = JSON.parse(atob(params));
            this.router.navigateByUrl(`/membresia?app_id=${btoa(paramsData.app_id)}`);
        } else if (hash.startsWith('#/event')) {
            const params = hash.replace('#/event/', '').split('?');
            this.router.navigateByUrl(`/eventos/inscricao-evento/${params[0]}?${params[1]}`);
        } else if (hash.startsWith('#/tickets')) {
            const params = hash.replace('#/tickets', '').split('?');
            this.router.navigateByUrl(`/eventos/meus-tickets?${params[1]}`);

        } else if (hash.startsWith('#/donation_types')) {
            const params = hash.replace('#/donation_types/', '').split('?');
            const decodeParams = atob(params[0]);
            const dataParams = JSON.parse(decodeParams);

            this.localStorageService.setItem('appId', dataParams.app_id);
            this.localStorageService.setItem('tertiary_group', dataParams.tertiarygroup_id);

            const appIdBtoa = btoa(dataParams.app_id);
            this.router.navigateByUrl(`/doacoes?app_id=${appIdBtoa}`);

        } else if (hash.startsWith('#/donation_payment')) {
            const params = hash.replace('#/donation_payment/', '').split('?');
            this.router.navigateByUrl(`/donation_payment/${params[0]}?${params[1]}`);
        } else if (hash.startsWith('#/register')) {
            this.router.navigateByUrl('/register');
        } else if (hash.startsWith('#/forgot_password')) {
            this.router.navigateByUrl('/forgot-password');
        } else if (hash.startsWith('#/redefine_password/')) {
            const token = hash.replace('#/redefine_password/', '');
            this.router.navigateByUrl(`/redefine-password/${token}`);
        } else if (hash.startsWith('#/account_confirmation/')) {
            const token = hash.replace('#/account_confirmation/', '');
            this.router.navigateByUrl(`/account-confirmation/${token}`);
        }
    }

    private listeningLocalstorage(): void {
        (function () {
            const originalSetItem = localStorage.setItem;
            const originalRemoveItem = localStorage.removeItem;

            class LocalStorageEvent extends Event {
                key: string;
                newValue?: string;
                constructor(type: string, key: string, newValue?: string) {
                    super(type);
                    this.key = key;
                    this.newValue = newValue;
                }
            }

            localStorage.setItem = function (key, value) {
                const event = new LocalStorageEvent('localstorage:set', key, value);
                document.dispatchEvent(event);
                originalSetItem.apply(this, arguments as unknown as [string, string]);
            };

            localStorage.removeItem = function (key) {
                const event = new LocalStorageEvent('localstorage:remove', key);
                document.dispatchEvent(event);
                originalRemoveItem.apply(this, arguments as unknown as [string]);
            };
        })();

        // Escutando os eventos personalizados
        document.addEventListener('localstorage:set', (event: any) => {
            if (event.key === 'appId') {
                Sentry.captureMessage('Evento localstorage:set disparado no Safari', 'info');
                this.tertiarygroupService.getSubGroup(event.newValue).subscribe({
                    next: subGroup => {
                        const logo = subGroup.logo || this.defaultLogo;
                        this.tertiarygroupService.subgroupLogoSubject.next(logo);
                    },
                    error: err => {
                        Sentry.captureException(err);
                        console.error('Erro ao buscar subgrupo:', err);
                    },
                });
            }
        });

        // document.addEventListener('localstorage:remove', (event: any) => {
        //     console.log(`Item removido do localStorage: ${event.key}`);
        // });
    }
}
