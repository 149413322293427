import { TranslateService } from '@ngx-translate/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
    constructor(public translationService: TranslateService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const authToken = localStorage.getItem('tkn');
        const appId = localStorage.getItem('appId');
        const tertiarygroup = localStorage.getItem('tertiary_group');
        const skipAuth = request.headers.get('skipAuth');
        const currentLanguage = this.translationService.currentLang || 'pt-BR';
        const storageHeaders: {
            authToken: string | null;
            tertiarygroup: string | null;
            appId: string | null;
        } = {
            authToken,
            tertiarygroup,
            appId,
        };

        if (request.url.includes('viacep.com.br')) {
            return next.handle(request);
        }

        let headers = new HttpHeaders()
            .set('Accept', 'application/json, text/plain, */*')
            .set('Content-Type', 'application/json;charset=UTF-8')
            .set('Channel', 'webapp')
            .set('appId', appId || '')
            .set('tertiaryGroup', tertiarygroup || '')
            .set('Accept-Language', `${currentLanguage},pt-BR;q=0.9,es;q=0.8,en;q=0.7`);

        if (!skipAuth) {
            headers = headers.set('Authorization', authToken || '');
        }

        if (tertiarygroup) {
            headers = headers.set('tertiarygroup', tertiarygroup);
        }

        for (const [key, value] of Object.entries(storageHeaders)) {
            if (value) {
                headers.set(key, value);
            }
        }

        headers.delete('skipAuth');

        const modifiedRequest = request.clone({
            headers: headers,
        });

        return next.handle(modifiedRequest);
    }
}
