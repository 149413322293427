import { Observable, catchError, map, switchMap, tap, throwError } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';
import { HttpService } from 'src/app/common/services/http.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private http: HttpService) {}

    public postLogin(body: any): Observable<any> {
        console.log('body', body);
        const endpoint = `api/v1/basic_user_person/login/`;
        const headers = new HttpHeaders().set('skipAuth', 'true');
        const data = body.token ? { body, headers: headers } : body;
        return this.http.post(endpoint, data).pipe(
            map(response => {
                this.handleLoginResponse(response);
            }),
            switchMap(() => this.getAccessFromServer()),
            catchError(error => {
                console.error('Error fetching access:', error);
                return throwError(error);
            }),
        );
    }

    public postRegister(body: any): Observable<any> {
        const endpoint = `api/v1/basic_user_person/`;
        const headers = new HttpHeaders().set('skipAuth', 'true');

        return this.http.post(endpoint, { body, headers: headers }).pipe(
            map(response => {
                console.log('Register response:', response);
                return response;
            }),
            switchMap(() => this.getAccessFromServer()),
            catchError(error => {
                console.error('Error fetching access:', error);
                return throwError(error);
            }),
        );
    }

    public verifyEmail(body: any): Observable<any> {
        const endpoint = `api/v1/basic_user_person/verify_email/`;
        return this.http.post(endpoint, body).pipe(
            map(response => {
                console.log('Email verification response:', response);
                return response;
            }),
            catchError(error => {
                console.error('Error verifying email:', error);
                return throwError(error);
            }),
        );
    }

    public redefinePassword(body: any): Observable<any> {
        const endpoint = `api/v1/basic_user_person/change_password/`;
        return this.http.post(endpoint, body).pipe(
            map(response => {
                console.log('Change passqord response:', response);
                return response;
            }),
            catchError(error => {
                console.error('Error change password:', error);
                return throwError(error);
            }),
        );
    }

    public forgotPassword(body: any): Observable<any> {
        const endpoint = `api/v1/basic_user_person/send_email_forgot_password/`;
        return this.http.post(endpoint, body).pipe(
            map(response => {
                console.log('Forgot password response:', response);
                return response;
            }),
            catchError(error => {
                console.error('Error forgot password:', error);
                return throwError(error);
            }),
        );
    }

    private handleLoginResponse(response: any): void {
        localStorage.setItem('user', JSON.stringify(response.basic_user));
        localStorage.setItem('siteUserId', response.additional.id);
        localStorage.setItem('siteUsername', response.additional.username);
        localStorage.setItem('siteApiKey', response.additional.api_key);
        localStorage.setItem('appId', response.additional.app_id);
        localStorage.setItem('tkn', response.authorization);
    }

    public isLogged(): boolean {
        return !!localStorage.getItem('tkn');
    }

    public getLoggedUser(): any {
        const storageUser = localStorage.getItem('user');
        return storageUser ? JSON.parse(storageUser) : null;
    }

    public clearLoginData(): void {
        localStorage.removeItem('user');
        localStorage.removeItem('siteUserId');
        localStorage.removeItem('siteUsername');
        localStorage.removeItem('siteApiKey');
        localStorage.removeItem('tkn');
        localStorage.removeItem('access');
    }

    public getAccessFromServer(): Observable<any> {
        const endpoint = 'api/v1/basic_user/access/';
        return this.http.get(endpoint).pipe(
            tap(access => {
                localStorage.setItem('access', JSON.stringify(access));
            }),
        );
    }
}
