export const locale = {
    lang: 'pt-PT',
    data: {
        PT_BR_TITLE: 'Português-BR',
        PT_PT_TITLE: 'Português-PT',
        EN_TITLE: 'Inglês',
        ES_TITLE: 'Espanhol',
        COUNTRY: "País",
        PAGINATOR: {
            ITEMS_PER_PAGE: 'Itens por página:',
            NEXT_PAGE: 'Página seguinte',
            PREVIOUS_PAGE: 'Página anterior',
            OF_LABEL: 'de',
        },
        ERRORS: {
            DENOMINATION_NOT_PROVIDED: 'Denominação não foi fornecida na URL!',
        },
        BILLET_MODAL: {
            TITLE: 'Boleto',
            DESCRIPTION: 'O seu boleto foi gerado com sucesso. Use o código de barras abaixo para efetuar o pagamento em qualquer banco, casa lotérica ou aplicação de pagamento.',
            COPY_CODE_BUTTON: 'Copiar código',
            CLOSE_BUTTON: 'Fechar',
        },
        PAGES_TITLES: {
            LOGIN_TITLE: 'Iniciar sessão',
            CELL_FINDER_TITLE: 'Localizador de Células',
            MY_TICKETS_TITLE: 'Os Meus Bilhetes',
            DONATIONS_TITLE: 'Doações',
            MEMBERSHIP_TITLE: 'Membresia',
            VISITORS_TITLE: 'Visitantes',
            MEMBERS_TITLE: 'Membros',
        },
        MENU: {
            USER: 'Utilizador',
            LOGIN: 'Iniciar sessão',
            MY_TICKETS: 'Os Meus Bilhetes',
            LOGOUT: 'Terminar sessão',
        },
        GENDER: {
            FEMALE: 'Feminino',
            MALE: 'Masculino',
        },
        CIVIL_STATES: {
            SINGLE: 'Solteiro(a)',
            ENGAGED: 'Noivo(a)',
            MARRIED: 'Casado(a)',
            DIVORCED: 'Divorciado(a)',
            WIDOWER: 'Viúvo(a)',
            STABLE_UNION: 'União de facto',
        },
        JOINING_TYPE: {
            BAPTISM: 'Batismo: fui batizado(a) na igreja.',
            TRANSFER: 'Transferência: vim de outra igreja e passei pelo processo de transferência.',
            RECONCILIATION: 'Reconciliação: estava afastado(a) e voltei para a igreja.',
            JURISDICTION: 'Jurisdição: vim de outra igreja da mesma denominação.',
            ACCLAIM: 'Aclamação: vim de outra igreja e passei pelo processo de aclamação.',
        },
        EDUCATIONAL_LEVEL: {
            UNSCHOOLED: 'Não Escolarizado',
            INCOMPLETED_PRIMARY: 'Ensino Básico Incompleto',
            COMPLETED_PRIMARY: 'Ensino Básico Completo',
            INCOMPLETED_HIGH_SCHOOL: 'Ensino Secundário Incompleto',
            COMPLETED_HIGH_SCHOOL: 'Ensino Secundário Completo',
            INCOMPLETED_HIGHER_EDUCATION: 'Ensino Superior Incompleto',
            COMPLETED_HIGHER_EDUCATION: 'Ensino Superior Completo',
            INCOMPLETED_GRADUATE: 'Pós-graduação Incompleta',
            COMPLETED_GRADUATE: 'Pós-graduação Completa',
            INCOMPLETED_MASTERS: 'Mestrado Incompleto',
            COMPLETED_MASTERS: 'Mestrado Completo',
            INCOMPLETED_DOC: 'Doutoramento Incompleto',
            COMPLETED_DOC: 'Doutoramento Completo',
            INCOMPLETED_POSTDOC: 'Pós-Doutoramento Incompleto',
            COMPLETED_POSTDOC: 'Pós-Doutoramento Completo',
        },
        SESSION: {
            ALL: 'Todos',
            MORNING: 'Manhã',
            AFTERNOON: 'Tarde',
            NIGHT: 'Noite',
        },
        WEEKDAYS: {
            SUNDAY: 'Domingo',
            MONDAY: 'Segunda-feira',
            TUESDAY: 'Terça-feira',
            WEDNESDAY: 'Quarta-feira',
            THURSDAY: 'Quinta-feira',
            FRIDAY: 'Sexta-feira',
            SATURDAY: 'Sábado',
        },
        PARENTAGE: {
            FATHER: 'Pai',
            MOTHER: 'Mãe',
            BROTHER: 'Irmão',
            SISTER: 'Irmã',
            GRANDFATHER: 'Avô',
            GRANDMOTHER: 'Avó',
            UNCLE: 'Tio',
            AUNT: 'Tia',
            STEPFATHER: 'Padrasto',
            STEPMOTHER: 'Madrasta',
            MALE_COUSIN: 'Primo',
            FEMALE_COUSIN: 'Prima',
            GREAT_GRANDFATHER: 'Bisavô',
            GREAT_GRANDMOTHER: 'Bisavó',
        },
        SNACKBAR: {
            LOGIN_FAILED: 'Erro ao iniciar sessão.',
            INVALID_DATA: 'Há um erro nos dados fornecidos.',
        },
    },
};
