import { Component, Input } from '@angular/core';

import { Router } from '@angular/router';
import { TertiarygroupsService } from '../../services/tertiarygroups.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input('drawer') drawer: any;

    routeTitles: { [key: string]: string } = {
        '/login': 'PAGES_TITLES.LOGIN_TITLE',
        '/buscador': 'PAGES_TITLES.CELL_FINDER_TITLE',
        '/buscador/enviar-indicacao': 'PAGES_TITLES.CELL_FINDER_TITLE',
        '/eventos/meus-tickets': 'PAGES_TITLES.MY_TICKETS_TITLE',
        '/doacoes': 'PAGES_TITLES.DONATIONS_TITLE',
        '/membresia': 'PAGES_TITLES.MEMBERSHIP_TITLE',
        '/membresia/visitantes': 'PAGES_TITLES.VISITORS_TITLE',
        '/membresia/membros': 'PAGES_TITLES.MEMBERS_TITLE'
    }

    switchTheme: boolean;
    defaultLogo: string = '../../../../assets/icons/inchurch.svg';
    logo: string;
    selectedLanguage: string;
    languages = [
        { code: 'es', flag: 'es', nameKey: 'ES_TITLE' },
        { code: 'pt', flag: 'pt', nameKey: 'PT_BR_TITLE' },
        { code: 'pt-PT', flag: 'pt-pt', nameKey: 'PT_PT_TITLE' },
        { code: 'en', flag: 'us', nameKey: 'EN_TITLE' }
      ];

    constructor(
        private router: Router,
        private tertiarygroupService: TertiarygroupsService,
        private translate: TranslateService
    ) {
        this.selectedLanguage = this.translate.currentLang || 'pt'; 
    }

    ngOnInit() {
        this.tertiarygroupService.subgroupLogo$.subscribe(logo => {
            this.logo = logo || this.defaultLogo;
        });

        let themeString = localStorage.getItem('panel-theme');

        if (themeString) {
            let themeObj = JSON.parse(themeString);
            themeObj.value
                ? (document.body.className = document.body.className.replace(
                      'theme-default-light',
                      'theme-default-dark',
                  ))
                : (document.body.className = document.body.className.replace(
                      'theme-default-dark',
                      'theme-default-light',
                  ));
            this.switchTheme = themeObj.value;
        }
    }

    changeLang(lang: string) {
        this.translate.use(lang);
        localStorage.setItem('panel-locale', JSON.stringify({ id: lang }));
        this.selectedLanguage = lang;   
    }

    getSelectedLanguage() {
        return this.languages.find(lang => lang.code === this.selectedLanguage);
      }

    isPaginaBuscador(): boolean {
        return this.router.url === '/buscador' || this.router.url === '/buscador/enviar-indicacao';
    }

    public getTitle(): string {
        const currentUrl = this.router.url;
        const defaultTitle = '';
        return this.routeTitles[currentUrl] || defaultTitle;
    }

    toggleSwitch() {
        this.switchTheme = !this.switchTheme;
        this.switchTheme === true
            ? (document.body.className = document.body.className.replace('theme-default-light', 'theme-default-dark'))
            : (document.body.className = document.body.className.replace('theme-default-dark', 'theme-default-light'));
        let theme = this.switchTheme
            ? { name: 'theme-default-dark', value: true }
            : { name: 'theme-default-light', value: false };
        localStorage.setItem('panel-theme', JSON.stringify(theme));
    }
}
