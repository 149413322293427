import * as Sentry from '@sentry/angular';

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CustomMatPaginatorIntl } from './common/utils/pagination';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './common/components/header/header.component';
import { HeadersInterceptor } from './common/interceptors/headers.interceptor';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatchaComponentsModule } from 'matcha-components';
import { MenuComponent } from './common/components/menu/menu.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from './common/services/translation-loader.service';
import { locale as english } from './common/i18n/en';
import { locale as portuguese } from './common/i18n/pt';
import { locale as spanish } from './common/i18n/es';
import { locale as portuguesePt } from './common/i18n/pt-portugal';


@NgModule({
    declarations: [AppComponent, HeaderComponent, MenuComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatFormFieldModule,
        FormsModule,
        MatSelectModule,
        RecaptchaV3Module,
        MatSnackBarModule,
        MatchaComponentsModule,
        TranslateModule.forRoot()
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeadersInterceptor,
            multi: true,
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.RECAPTCHA_KEY,
        },
        { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {

    constructor(private translate: TranslateService, private translationLoader: TranslationLoaderService) {
        translate.setDefaultLang('pt');

        translationLoader.loadTranslations(english, portuguese, portuguesePt, spanish);

        const savedLang = localStorage.getItem('panel-locale');
        if (savedLang) {
            translate.use(JSON.parse(savedLang).id);
        } else {
            translate.use('pt');
        }
    }
}
