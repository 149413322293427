export const locale = {
    lang: 'pt',
    data: {
        PT_BR_TITLE: 'Português-BR',
        PT_PT_TITLE: 'Português-PT',
        EN_TITLE: 'Inglês',
        ES_TITLE: 'Espanhol',
        COUNTRY: "País",
        PAGINATOR: {
            ITEMS_PER_PAGE: 'Itens por página:',
            NEXT_PAGE: 'Próxima página',
            PREVIOUS_PAGE: 'Página anterior',
            OF_LABEL: 'de',
        },
        ERRORS: {
            DENOMINATION_NOT_PROVIDED: 'Denominação não foi informada na URL!',
        },
        BILLET_MODAL: {
            TITLE: 'Boleto',
            DESCRIPTION: 'Seu boleto foi gerado com sucesso. Use o código de barras abaixo para efetuar o pagamento em qualquer banco, lotérica ou aplicativo de pagamento.',
            COPY_CODE_BUTTON: 'Copiar código',
            CLOSE_BUTTON: 'Fechar',
        },
        PAGES_TITLES: {
            LOGIN_TITLE: 'Login',
            CELL_FINDER_TITLE: 'Buscador de Células',
            MY_TICKETS_TITLE: 'Meus Tickets',
            DONATIONS_TITLE: 'Doações',
            MEMBERSHIP_TITLE: 'Membresia',
            VISITORS_TITLE: 'Visitantes',
            MEMBERS_TITLE: 'Membros',
        },
        MENU: {
            USER: 'Usuário',
            LOGIN: 'Login',
            MY_TICKETS: 'Meus Tickets',
            LOGOUT: 'Sair',
        },
        GENDER: {
            FEMALE: 'Feminino',
            MALE: 'Masculino',
        },
        CIVIL_STATES: {
            SINGLE: 'Solteiro(a)',
            ENGAGED: 'Noivo(a)',
            MARRIED: 'Casado(a)',
            DIVORCED: 'Divorciado(a)',
            WIDOWER: 'Viúvo(a)',
            STABLE_UNION: 'União estável',
        },
        JOINING_TYPE: {
            BAPTISM: 'Batismo: fui batizado(a) na igreja.',
            TRANSFER: 'Transferência: vim de outra igreja e passei pelo processo de transferência.',
            RECONCILIATION: 'Reconciliação: estava afastado e voltei para a igreja.',
            JURISDICTION: 'Jurisdição: vim de outra igreja da mesma denominação.',
            ACCLAIM: 'Aclamação: vim de outra igreja e passei pelo processo de aclamação.',
        },
        EDUCATIONAL_LEVEL: {
            UNSCHOOLED: 'Não Escolarizado',
            INCOMPLETED_PRIMARY: 'Ensino Fundamental Incompleto',
            COMPLETED_PRIMARY: 'Ensino Fundamental Completo',
            INCOMPLETED_HIGH_SCHOOL: 'Ensino Médio Incompleto',
            COMPLETED_HIGH_SCHOOL: 'Ensino Médio Completo',
            INCOMPLETED_HIGHER_EDUCATION: 'Ensino Superior Incompleto',
            COMPLETED_HIGHER_EDUCATION: 'Ensino Superior Completo',
            INCOMPLETED_GRADUATE: 'Pós-graduação Lato senso Incompleta',
            COMPLETED_GRADUATE: 'Pós-graduação Lato senso Completa',
            INCOMPLETED_MASTERS: 'Mestrado Incompleto',
            COMPLETED_MASTERS: 'Mestrado Completo',
            INCOMPLETED_DOC: 'Doutorado Incompleto',
            COMPLETED_DOC: 'Doutorado Completo',
            INCOMPLETED_POSTDOC: 'Pós-Doutorado Incompleto',
            COMPLETED_POSTDOC: 'Pós-Doutorado Completo',
        },
        SESSION: {
            ALL: 'Todos',
            MORNING: 'Manhã',
            AFTERNOON: 'Tarde',
            NIGHT: 'Noite',
        },
        WEEKDAYS: {
            SUNDAY: 'Domingo',
            MONDAY: 'Segunda-feira',
            TUESDAY: 'Terça-feira',
            WEDNESDAY: 'Quarta-feira',
            THURSDAY: 'Quinta-feira',
            FRIDAY: 'Sexta-feira',
            SATURDAY: 'Sábado',
        },
        PARENTAGE: {
            FATHER: 'Pai',
            MOTHER: 'Mãe',
            BROTHER: 'Irmão',
            SISTER: 'Irmã',
            GRANDFATHER: 'Avô',
            GRANDMOTHER: 'Avó',
            UNCLE: 'Tio',
            AUNT: 'Tia',
            STEPFATHER: 'Padrasto',
            STEPMOTHER: 'Madrasta',
            MALE_COUSIN: 'Primo',
            FEMALE_COUSIN: 'Prima',
            GREAT_GRANDFATHER: 'Bisavô',
            GREAT_GRANDMOTHER: 'Bisavó',
        },
        SNACKBAR: {
            LOGIN_FAILED: 'Erro ao realizar o login.',
            INVALID_DATA: 'Existe um erro nos dados fornecidos.',
        },
    },
};
