export const locale = {
    lang: 'en',
    data: {
        PT_BR_TITLE: 'Portuguese-BR',
        PT_PT_TITLE: 'Portuguese-PT',
        EN_TITLE: 'English',
        ES_TITLE: 'Spanish',
        COUNTRY: "Cuntry",
        PAGINATOR: {
            ITEMS_PER_PAGE: 'Items per page:',
            NEXT_PAGE: 'Next page',
            PREVIOUS_PAGE: 'Previous page',
            OF_LABEL: 'of',
        },
        ERRORS: {
            DENOMINATION_NOT_PROVIDED: 'Denomination was not provided in the URL!',
        },
        BILLET_MODAL: {
            TITLE: 'Bill',
            DESCRIPTION: 'Your bill was generated successfully. Use the barcode below to make the payment at any bank, lottery outlet, or payment app.',
            COPY_CODE_BUTTON: 'Copy code',
            CLOSE_BUTTON: 'Close',
        },
        PAGES_TITLES: {
            LOGIN_TITLE: 'Login',
            CELL_FINDER_TITLE: 'Cell Finder',
            MY_TICKETS_TITLE: 'My Tickets',
            DONATIONS_TITLE: 'Donations',
            MEMBERSHIP_TITLE: 'Membership',
            VISITORS_TITLE: 'Visitors',
            MEMBERS_TITLE: 'Members',
        },
        MENU: {
            USER: 'User',
            LOGIN: 'Login',
            MY_TICKETS: 'My Tickets',
            LOGOUT: 'Logout',
        },
        GENDER: {
            FEMALE: 'Female',
            MALE: 'Male',
        },
        CIVIL_STATES: {
            SINGLE: 'Single',
            ENGAGED: 'Engaged',
            MARRIED: 'Married',
            DIVORCED: 'Divorced',
            WIDOWER: 'Widower',
            STABLE_UNION: 'Stable union',
        },
        JOINING_TYPE: {
            BAPTISM: 'Baptism: I was baptized in the church.',
            TRANSFER: 'Transfer: I came from another church and went through the transfer process.',
            RECONCILIATION: 'Reconciliation: I was away and returned to the church.',
            JURISDICTION: 'Jurisdiction: I came from another church of the same denomination.',
            ACCLAIM: 'Acclamation: I came from another church and went through the acclamation process.',
        },
        EDUCATIONAL_LEVEL: {
            UNSCHOOLED: 'Uneducated',
            INCOMPLETED_PRIMARY: 'Incomplete Primary Education',
            COMPLETED_PRIMARY: 'Completed Primary Education',
            INCOMPLETED_HIGH_SCHOOL: 'Incomplete High School',
            COMPLETED_HIGH_SCHOOL: 'Completed High School',
            INCOMPLETED_HIGHER_EDUCATION: 'Incomplete Higher Education',
            COMPLETED_HIGHER_EDUCATION: 'Completed Higher Education',
            INCOMPLETED_GRADUATE: 'Incomplete Graduate Degree',
            COMPLETED_GRADUATE: 'Completed Graduate Degree',
            INCOMPLETED_MASTERS: "Incomplete Master's Degree",
            COMPLETED_MASTERS: "Completed Master's Degree",
            INCOMPLETED_DOC: 'Incomplete Doctorate',
            COMPLETED_DOC: 'Completed Doctorate',
            INCOMPLETED_POSTDOC: 'Incomplete Postdoctoral',
            COMPLETED_POSTDOC: 'Completed Postdoctoral',
        },
        SESSION: {
            ALL: 'All',
            MORNING: 'Morning',
            AFTERNOON: 'Afternoon',
            NIGHT: 'Night',
        },
        WEEKDAYS: {
            SUNDAY: 'Sunday',
            MONDAY: 'Monday',
            TUESDAY: 'Tuesday',
            WEDNESDAY: 'Wednesday',
            THURSDAY: 'Thursday',
            FRIDAY: 'Friday',
            SATURDAY: 'Saturday',
        },
        PARENTAGE: {
            FATHER: 'Father',
            MOTHER: 'Mother',
            BROTHER: 'Brother',
            SISTER: 'Sister',
            GRANDFATHER: 'Grandfather',
            GRANDMOTHER: 'Grandmother',
            UNCLE: 'Uncle',
            AUNT: 'Aunt',
            STEPFATHER: 'Stepfather',
            STEPMOTHER: 'Stepmother',
            MALE_COUSIN: 'Cousin',
            FEMALE_COUSIN: 'Cousin',
            GREAT_GRANDFATHER: 'Great-grandfather',
            GREAT_GRANDMOTHER: 'Great-grandmother',
        },
        SNACKBAR: {
            LOGIN_FAILED: 'Error logging in.',
            INVALID_DATA: 'There is an error in the provided data.',
        },
    },
};
